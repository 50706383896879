// import NProgress from "nprogress";
import { gsap } from "gsap";

let tl = gsap.timeline();

const circlePathsTop = document.querySelectorAll(
  "svg.background_circles g#arches_top"
);

const circlePathsTopRow1Lines = document.querySelectorAll(
  "svg.background_circles g#arches_top > g#row_1 > *"
);

const circlePathsTopRow2Lines = document.querySelectorAll(
  "svg.background_circles g#arches_top > g#row_2 > *"
);

gsap.from(circlePathsTop, { yPercent: -35, duration: 8 });

tl.to(circlePathsTopRow2Lines, {
  opacity: 1,
  duration: 2,
  ease: "sine.out",
  stagger: {
    each: 0.25,
    from: "start",
  },
}).to(
  circlePathsTopRow1Lines,
  {
    opacity: 1,
    duration: 2,
    ease: "sine.out",
    stagger: {
      each: 0.25,
      from: "end",
    },
  },
  "-=0.75"
);
